import { decrypt, encrypt } from "../../crypto-utils";


export class LocalStorage {
    secretKey: string;
    secretIv: string;
    constructor(secretKey: string, secretIv: string) {
        this.secretKey = secretKey;
        this.secretIv = secretIv;
    }

    collection(name: string) {
        const secretKey = this.secretKey
        const secretIv = this.secretIv
        if (!secretKey) {
            throw new Error("Database not initialized.");
        } else if (!this.secretIv) {
            throw new Error("Database not initialized.");
        }
        return new Collection(name, secretKey, secretIv);
    }

    clear() {
        localStorage.clear();
    }
}

class Collection {
    private secretKey: string;
    private secretIv: string;
    private name: string;

    constructor(name: string, secretKey: string, secretIv: string) {
        this.secretKey = secretKey;
        this.secretIv = secretIv;
        this.name = name;
    }
    async set(data: any): Promise<any> {
        try {
            if (typeof data === "string") {
                let encryptedData = await encrypt(data, this.secretKey, this.secretIv);
                localStorage.setItem(this.name, JSON.stringify(encryptedData))
                console.log("Data has been added/updated successfully.");
                return "Data has been added/updated successfully."
            } else {
                let encryptedData = await encrypt(JSON.stringify(data), this.secretKey, this.secretIv);
                localStorage.setItem(this.name, JSON.stringify(encryptedData))
                console.log("Data has been added/updated successfully.");
                return "Data has been added/updated successfully."
            }
        } catch (err) {
            console.error("Data addition/update error:", err);
            return null
        }
    }

    async get(): Promise<any> {
        try {
            const localData = localStorage.getItem(this.name)
            if (localData) {

                const data = JSON.parse(localData)
                const decryptedData = await decrypt(data, this.secretKey, this.secretIv);

                if (typeof decryptedData === "string") {
                    const regex = /[\[\]\{\}]/;
                    if (regex.test(decryptedData)) {
                        return JSON.parse(decryptedData)
                    } else {
                        return decryptedData
                    }


                } else {
                    return decryptedData
                    // console.log('Data retrieved:', decryptedData);
                }
            } else {
                throw new Error("Error: local storage not key");
            }
        } catch (err) {
            console.error('Error retrieving data:', err);
            return null
        }
    }
    async remove(): Promise<any> {
        try {

            localStorage.removeItem(this.name);
            console.log('Data deleted successfully.');
            return null
        } catch (err) {
            console.error('Error deleting data:', err);
            return null
        }
    }
}
