import { createSlice } from '@reduxjs/toolkit';

interface LoginState {
  value: boolean;
}

const initialState: LoginState = {
  value: false,
};

const counterSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    useLoginReducer: (state) => {
      state.value = true;
    },
    useLogoutReducer: (state) => {
      state.value = false;
    }
  },
});

export const { useLoginReducer, useLogoutReducer } = counterSlice.actions;
export default counterSlice.reducer;