import CryptoJS from 'crypto-js';

export async function encryptData(secretKey: any, data: any) {
    const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();

    return encrypted;
}

export async function decryptData(secretKey: any, encryptedData: any) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return decrypted
}

export function encryptBase64Url(text: string, keyString: string, ivString: string) {

    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    let cipherBytes = CryptoJS.AES.encrypt(text, key, options);
    const base64 = cipherBytes.toString();
    const base64Url = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64Url;
}

export function decryptBase64Url(encryptedText: string, keyString: string, ivString: string) {
    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    let base64 = encryptedText.replace(/-/g, '+').replace(/_/g, '/') + '==='.slice(0, (4 - encryptedText.length % 4) % 4);
    let cipherBytes = CryptoJS.AES.decrypt(base64, key, options);

    return cipherBytes.toString(CryptoJS.enc.Utf8);
}


export function encrypt(text: string, keyString: string, ivString: string) {

    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    let cipherBytes = CryptoJS.AES.encrypt(text, key, options);
    return cipherBytes;
}

export function decrypt(encryptedText: string, keyString: string, ivString: string) {
    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    let cipherBytes = CryptoJS.AES.decrypt(encryptedText, key, options);
    console.log(cipherBytes.toString(CryptoJS.enc.Utf8));

    return cipherBytes.toString(CryptoJS.enc.Utf8);
}

export function encryptHex(text: string, keyString: string, ivString: string) {

    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        format: CryptoJS.format.Hex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    let cipherBytes = CryptoJS.AES.encrypt(text, key, options);
    return cipherBytes.toString();
}

export function decryptHex(encryptedText: string, keyString: string, ivString: string) {
    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        format: CryptoJS.format.Hex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    let cipherBytes = CryptoJS.AES.decrypt(encryptedText, key, options);
    return cipherBytes.toString(CryptoJS.enc.Utf8);
}

export function encryptProgressive(textArray: any[], keyString: string, ivString: string) {

    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        format: CryptoJS.format.Hex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    var aesEncryptor = CryptoJS.algo.AES.createEncryptor(key, options);

    let cipherBytes = textArray.map(text => {

        let enc = aesEncryptor.process(text)
        enc = aesEncryptor.finalize()
        return enc
    })

    return cipherBytes;
}

export function decryptProgressive(cipherBytes: any[], keyString: string, ivString: string) {

    let iv = CryptoJS.enc.Hex.parse(ivString);

    let key = CryptoJS.enc.Hex.parse(keyString);

    const options = {
        iv,
        format: CryptoJS.format.Hex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    };

    var aesDecryptor = CryptoJS.algo.AES.createDecryptor(key, options);

    let textArray = cipherBytes.map(cipher => {
        let dec = aesDecryptor.process(cipher).toString(CryptoJS.enc.Utf8)
        dec = aesDecryptor.finalize().toString(CryptoJS.enc.Utf8)
        return dec

    });

    return textArray;
}