import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './shared/reducers/store';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import theme from './theme';
import { HashRouter } from 'react-router-dom';
import LogicProvider from './providers/LogicProvider';
import { Secret } from './shared/libs/secret';
import { Key } from './shared/libs/key';
import { Iv } from './shared/libs/iv';
import { Pipeline } from './shared/libs/pipeline';
import { decryptHex } from './shared/libs/crypto-utils';
import git from 'isomorphic-git'

const secret = new Secret()
const key = new Key()
const iv = new Iv()
const pipeline = new Pipeline()

secret.init()
key.init()
iv.init()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

async function init() {
  let secretRemotes = await git.listRemotes({ fs: secret.fs, dir: secret.dir })
  let keyRemotes = await git.listRemotes({ fs: key.fs, dir: key.dir })
  let ivRemotes = await git.listRemotes({ fs: iv.fs, dir: iv.dir })

  if (secretRemotes.length > 0 && keyRemotes.length > 0 && ivRemotes.length > 0) {
    const _secret: any = await secret.get()
    const _key: any = await key.get()
    const _iv: any = await iv.get()

    const accessToken = decryptHex(_secret, _key, _iv)
    pipeline.init(accessToken)

    let pipelineRemotes = await git.listRemotes({ fs: pipeline.fs, dir: pipeline.dir })
    
    if (pipelineRemotes.length > 0) {

      root.render(
        <React.StrictMode>
          <LogicProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Provider store={store}>
                <HashRouter >
                  <App config={{
                    secret: secret,
                    key: key,
                    iv: iv,
                  }} />
                </HashRouter >
              </Provider>
            </ThemeProvider>
          </LogicProvider>
        </React.StrictMode>
      );
    }else {
      setTimeout(() => {
        window.location.reload()
      },2000)
    }
  }else {
    setTimeout(() => {
      window.location.reload()
    },2000)
  }
}

init()





// async function initGApi() {
//   await loadScript('gapi','https://apis.google.com/js/api.js');
// }

// initGApi()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
