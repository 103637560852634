import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  value: any;
}

const initialState: UserState = {
  value: {},
};

const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    useUserReducer: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    }
  },
});

export const { useUserReducer } = counterSlice.actions;
export default counterSlice.reducer;