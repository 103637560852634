import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OpenMenuState {
  value: boolean;
}

const initialState: OpenMenuState = {
  value: false,
};

const counterSlice = createSlice({
  name: 'openMenu',
  initialState,
  reducers: {
    useOpenMenuReducer: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    }
  },
});

export const { useOpenMenuReducer } = counterSlice.actions;
export default counterSlice.reducer;