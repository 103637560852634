import { decryptBase64Url, encryptBase64Url, } from "../../libs/crypto-utils";
export class Token {
    key: string
    iv: string
    constructor(key: string, iv: string) {
        this.iv = iv
        this.key = key
    }

    private getAllLocalStorage() {
        const allItems: any = {};

        // ใช้ Object.keys เพื่อรับคีย์ทั้งหมดใน localStorage
        Object.keys(localStorage).forEach(key => {
            allItems[key] = localStorage.getItem(key); // เก็บค่าที่ได้ในออบเจ็กต์
        });

        return allItems; // คืนค่าออบเจ็กต์ที่มีคีย์และค่า
    }

    private openDatabase() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open('firebaseLocalStorageDb', 1);

            request.onupgradeneeded = (event: any) => {
                const db = event.target.result;
                db.createObjectStore('firebaseLocalStorage', { keyPath: 'id' });
            };

            request.onsuccess = (event: any) => {
                resolve(event.target.result);
            };

            request.onerror = (event: any) => {
                reject(event.target.error);
            };
        });
    }

    private async getData(id: any) {
        const db: any = await this.openDatabase();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction('firebaseLocalStorage', 'readonly');
            const store = transaction.objectStore('firebaseLocalStorage');
            const request = store.get(id);

            request.onsuccess = (event: any) => {
                resolve(event.target.result);
            };

            request.onerror = (event: any) => {
                reject(event.target.error);
            };
        });
    }

    private async getAllData() {
        const db: any = await this.openDatabase();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction('firebaseLocalStorage', 'readonly');
            const store = transaction.objectStore('firebaseLocalStorage');
            const request = store.getAll(); // ใช้ getAll เพื่อดึงข้อมูลทั้งหมด

            request.onsuccess = (event: any) => {
                resolve(event.target.result);
            };

            request.onerror = (event: any) => {
                reject(event.target.error);
            };
        });
    };

    private async setData(data: any) {
        const db: any = await this.openDatabase();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction('firebaseLocalStorage', 'readwrite');
            const store = transaction.objectStore('firebaseLocalStorage');
            const request = store.put(data); // ใช้ put เพื่อเพิ่มหรืออัปเดตข้อมูล

            request.onsuccess = () => {
                resolve('Data saved successfully');
            };

            request.onerror = (event: any) => {
                reject(event.target.error);
            };
        });
    }

    async new() {
        const token:any = await this.getAllData()
        let tokenStr = JSON.stringify(token)
        console.log(token);
        
        return encryptBase64Url(tokenStr, this.key, this.iv)

    }

    async read(token: string) {
        const dataStr = decryptBase64Url(token, this.key, this.iv)
        const data = JSON.parse(dataStr)
        this.setData(data[0])
        return data
    }
}