import * as React from 'react';
import { Logic } from "../shared/logic";
import { AppDispatch } from '../shared/reducers/store';


interface LogicContextType {
    logic?: Logic;
    setup: (payload: { dispatch: AppDispatch, config: { secret: any, key: any, iv: any } }, callback: (logicData: Logic) => any) => any
}

export let LogicContext = React.createContext<LogicContextType>(null!);

export function useLogic() {
    return React.useContext(LogicContext);
}

export const provider = {
    setup(payload: { dispatch: AppDispatch, config: { secret: string, key: string, iv: string } }, callback: (logicData: Logic) => any) {
        const logic = new Logic({
            dispatch: payload.dispatch, config: payload.config
        })
        callback(logic)

    }
}

export function LogicProvider({ children }: { children?: React.ReactNode }) {
    let [logic, setLogic] = React.useState<Logic>();

    let setup = (payload: { dispatch: AppDispatch, config: { secret: string, key: string, iv: string } }, callback: (logicData: Logic) => any) => {


        return provider.setup(payload, (logicData: Logic) => {
            setLogic(logicData)
            callback(logicData);
        });
    };

    let value = { logic, setup };

    return (<LogicContext.Provider value={value} > {children} </LogicContext.Provider>);
}

export default LogicProvider;