import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Button, Divider, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../shared/reducers/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useLogic } from '../providers/LogicProvider';

export default function DenseAppBar({ position = "static" }: {
    position?: 'absolute'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky'
}) {
    const login = useSelector((state: RootState) => state.login?.value);
    const user = useSelector((state: RootState) => state.user.value);
    const openMenu = useSelector((state: RootState) => state.openMenu.value);
    const location = useLocation();
    const { logic } = useLogic()
    const setting: any = {
        mode: {
            public: {
                appbar: {
                    sx: {
                        backgroundColor: "#00000000",
                        color: "#fff",
                    },
                    icon: "icon-w.svg",
                    menu: [
                        {
                            id: "products",
                            name: "Products",
                            submenu: []
                        }
                    ]
                }
            },
            login: {
                appbar: {
                    sx: {
                        backgroundColor: "#00000000",
                        color: "#000",
                    },
                    icon: "icon-b.svg",
                    menu: []
                }
            },
            register: {
                appbar: {
                    sx: {
                        backgroundColor: "#00000000",
                        color: "#000",
                    },
                    icon: "icon-b.svg",
                    menu: []
                }
            },
            private: {
                appbar: {
                    sx: {
                        backgroundColor: "#fff",
                        borderBottomWidth: '1px',
                        color: "#000",
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'rgba(0, 0, 0, 0.12)'
                    },
                    icon: "icon-b.svg",
                    menu: []
                }
            }
        }
    }
    const dispatch: AppDispatch = useDispatch();
    // const logoutLogic = new LogoutLogic(dispatch)
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [accessToken, setAccessToken] = React.useState<any>("")

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function logOut() {
        setAnchorEl(null);
        logic?.auth().logout()
        navigate('/', { replace: true });
    }

    function onOpenMenu() {
        dispatch({
            payload: !openMenu,
            type: "openMenu/useOpenMenuReducer",
        })
    }

    function mode() {
        if (login) {
            return 'public'
        } else {
            if (location.pathname.includes("login")) {
                return "login"
            } else if (location.pathname.includes("register")) {
                return "register"
            } else {
                return "public"
            }
        }
    }

    async function init() {
        logic?.token().new().then((newToken:any) => {
            setAccessToken(newToken)
        })
    }

    React.useEffect(() => {
        init()
    }, [logic])



    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar elevation={0} position={position} sx={setting.mode[`${mode()}`].appbar.sx}>
                <Toolbar sx={{ p: 0 }}>
                    <Tooltip title={"หน้าแรก"}>
                        <Button component={Link} color="inherit" to='/' startIcon={<img src={setting.mode[`${mode()}`].appbar.icon} style={{ width: '45px' }} />}> <Typography variant="h6" color="inherit" component="div" sx={{ mr: 3 }}>
                            IDev Coder
                        </Typography></Button>
                    </Tooltip>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', marginTop: '5px' } }}>
                        {setting.mode[`${mode()}`].appbar.menu.length > 0 && setting.mode[`${mode()}`].appbar.menu.map((menu: any) => (
                            <Button
                                key={menu.id}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {menu.name}
                            </Button>
                        ))}
                    </Box>
                    <div style={{ marginRight: '20px' }}>
                        {login ? (<>
                            <Button href={`https://console.idevcoder.com/#/?token=${accessToken}`}>Go to console</Button>
                        </>) : (<>
                            <Button href={`https://console.idevcoder.com/#/`}>Go to console</Button>
                        </>)}

                    </div>

                    {login ? (<>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title={<>
                                <p>IDev Coder Account</p>
                                {user.displayName && <p>{user.displayName}</p>}
                                {user.email && <p>{user.email}</p>}
                            </>}>
                                <IconButton sx={{ p: 0 }}
                                    id="manu-account"
                                    aria-controls={open ? 'manu-account' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <Avatar alt={user.displayName ? user.displayName : user.email} src={user.photoURL} sx={{ width: 35, height: 35 }} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="manu-account"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'manu-account',
                                }}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={user.displayName} src={user.photoURL} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.displayName}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                >
                                                    {user.email}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider />
                                <MenuItem onClick={() => {
                                    window.location.replace(`https://idev-coder-console.github.io/manage-profile/#/?token=${accessToken}`)
                                }}>
                                    <ListItemIcon>
                                        <i className="fa-duotone fa-solid fa-user-gear"></i>
                                    </ListItemIcon>
                                    <ListItemText>ตั้งค่า โปรไฟล์</ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={logOut}>
                                    <ListItemIcon>
                                        <i className="fa-duotone fa-solid fa-arrow-left-from-bracket"></i>
                                    </ListItemIcon>
                                    <ListItemText>ลงชื่อออก</ListItemText>
                                </MenuItem>
                            </Menu>
                            {/* <Button color="inherit" variant="outlined" onClick={logOut}>Logout</Button> */}
                        </Box>
                    </>) : (<>
                        {mode() === 'public' && (<>
                            <Button component={Link} color="inherit" sx={{ mr: 3 }} to='/login'>ลงชื่อเข้าสู่ระบบ</Button>
                            <Button component={Link} color="inherit" variant="outlined" to='/register'>สมัครสมาชิก</Button>
                        </>)}
                        {mode().includes("register") && (<Button component={Link} variant="outlined" to='/login'>ลงชื่อเข้าสู่ระบบ</Button>)}
                        {mode().includes("login") && (<Button component={Link} variant="outlined" to='/register'>สมัครสมาชิก</Button>)}

                    </>)}

                </Toolbar>
            </AppBar>

        </Box>
    );
}