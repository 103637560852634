import { SnackbarOrigin } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAlert extends SnackbarOrigin {
  open: boolean
  severity: 'error' | 'info' | 'success' | 'warning' 
  message: string,
  variant: 'outlined'|'filled'|'standard'
}

interface AlertState {
  value: IAlert;
}

const initialState: AlertState = {
  value: {
    open: false,
    severity: "success",
    message: "",
    vertical: "top",
    horizontal: "center",
    variant:"outlined"
  },
};

const counterSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    useAlertReducer: (state, action: PayloadAction<IAlert>) => {
      state.value = action.payload;
    }
  },
});

export const { useAlertReducer } = counterSlice.actions;
export default counterSlice.reducer;