import React, { useEffect } from "react";
import { gtag } from "../utils/gtag";
import { useLocation } from "react-router-dom";

const TrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    gtag.event({
      action: "page_view",
      category: "Page",
      label: location.pathname,
    });
  }, [location]);

  return null;
};

export default TrackPage