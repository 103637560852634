declare global {
    interface Window {
      gtag: (...args: any[]) => void;
    }
  }
  
  export const gtag = {
    event: ({ action, category, label, value }: { action: string; category: string; label?: string; value?: number }) => {
      if (typeof window.gtag === "function") {
        window.gtag("event", action, {
          event_category: category,
          event_label: label,
          value,
        });
      }
    },
  };