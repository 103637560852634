import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './login.slice';
import alertReducer from './alert.slice';
import userReducer from './user.slice';
import openMenuReducer from './open-menu.slice';

const store = configureStore({
    reducer: {
        login: loginReducer,
        alert: alertReducer,
        user: userReducer,
        openMenu: openMenuReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;