import git from 'isomorphic-git'
import LightningFS from '@isomorphic-git/lightning-fs'
import http from 'isomorphic-git/http/web'

export class Pipeline {
    dir: string = '/'
    fs = new LightningFS('pipeline')
    http: any = http
    corsProxy: string = 'https://cors.isomorphic-git.org'
    ref: string = 'HEAD'
    author: { name: string, email: string } = {
        name: 'idev-coder',
        email: 'idevcoder7@gmail.com'
    }
    constructor() {}

    init(accessToken: string) {
        const fs = this.fs
        const dir = this.dir
        const ref = this.ref
        const author = this.author
        const http = this.http
        const corsProxy = this.corsProxy
        const url = `https://${accessToken}@github.com/idev-coder-console/pipeline.git`
        git.listFiles({ fs, dir: dir, ref }).then((listFIles) => {
            if (listFIles) {
                git.pull({
                    fs,
                    http,
                    dir,
                    author
                }).then(() => {
                    git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                        console.log("git checkout: ", `${process.env.NODE_ENV}`);
                    }).catch((err) => {
                        console.log("git checkout err: ", err);
                    })
                })
            }
        }).catch((err) => {
            git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() => {
                // window.location.reload()
                git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                    console.log("git checkout: ", `${process.env.NODE_ENV}`);
                   
                    
                }).catch((err) => {
                    console.log("git checkout err: ", err);
                })
            })
        })


    }

    async get() {
        const environmentFile: any = await this.fs.promises.readFile('/config/environment.json', 'utf8')
        const extensionFile: any = await this.fs.promises.readFile('/apps/extension.json', 'utf8')
        const mainFile: any = await this.fs.promises.readFile('/apps/main.json', 'utf8')

        return {
            apps: {
                extension: JSON.parse(extensionFile),
                main: JSON.parse(mainFile),
            },
            config: {
                environment: JSON.parse(environmentFile)
            }
        }


    }
}