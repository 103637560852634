import { Alert, Slide, SlideProps, Snackbar, SnackbarOrigin } from "@mui/material";
import { useDispatch } from "react-redux";

import React from "react";
import { connect } from 'react-redux';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function AlertPopup({ alert }: { alert: any }) {
    const dispatch = useDispatch()
    const { open, severity, message, vertical, horizontal, variant } = alert

    function handleClose() {
        dispatch({
            payload: {
                open: false,
                severity: "",
                message: ``,
                vertical: "top",
                horizontal: "center"
            },
            type: "alert/useAlertReducer"
        })
    }

    return (

        <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            key={vertical + horizontal}
            TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                variant={variant}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>

    );
}

const mapStateToProps = (state: any) => ({
    alert: state.alert.value, // ต้องให้ถูกต้องตามชื่อ reducer
});

export default connect(mapStateToProps)(AlertPopup);