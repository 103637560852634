import { Config } from "../../config"
import { LocalStorage } from "../libs/local/storage"
import { AppDispatch } from "../reducers/store"
import { Service } from "../services"
import { AuthLogic } from "./auth"
import { Token } from "./token"


export class Logic {
    dispatch: AppDispatch
    localStorage: LocalStorage
    service: Service
    config: Config
    constructor({ dispatch, config }: {
        dispatch: AppDispatch, config: {
            secret: string,
            key: string,
            iv: string,
        }
    }) {
        this.dispatch = dispatch
        this.config = new Config(config)
        this.service = new Service(this.config)
        this.localStorage = new LocalStorage(config.key, config.iv)
    }

    auth() {
        return new AuthLogic({
            dispatch: this.dispatch,
            localStorage: this.localStorage,
            service: this.service,
            config: this.config
        })
    }

    token() {
        return new Token(this.config.key, this.config.iv)
    }

}