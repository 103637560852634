import * as React from 'react';
import Background from '../../components/Backgroud';
import DenseAppBar from '../../components/DenseAppBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../shared/reducers/store';
import PublicPage from '../public';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLogic } from '../../providers/LogicProvider';

export default function HomePage() {
    const login = useSelector((state: RootState) => state?.login.value);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const { logic } = useLogic()
    React.useEffect(() => {
        const queryToken = searchParams.get("token")
        if (queryToken) {
            logic?.token().read(queryToken).then((res) => {
                navigate('/', { replace: true });
            })
        }


    }, [])
    return (<>
        <Background>
            <DenseAppBar position="fixed"></DenseAppBar>
            <div style={{
                zIndex: 1,
                position: 'relative',
            }}>
                <PublicPage></PublicPage>
            </div>
        </Background>
    </>)
}